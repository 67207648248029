import axios from 'axios';
import classNames from 'classnames';
import {useSnackbar} from 'notistack';
import {useForm} from 'react-hook-form';
import {useQuery} from '@tanstack/react-query';
import {isEmpty, get, isUndefined, isString, trim, mapValues} from 'lodash';

import {preventDefault} from '@fullcalendar/react';
import React, {FormEvent, useState, FC} from 'react';

import {Button} from 'pages/generic/form/Button';
import {usePostData} from 'tools/hooks/postData';
import LoadingSpinner from 'pages/generic/LoadingSpinner';
import {useKeycloak} from 'tools/contexts/KeycloakContext';
import {showActionNotification} from 'pages/generic/showActionNotification';

import {
    FormField,
    FormProps,
    CAVOKInputSave,
    ValidationRequestError,
} from '../types';
import {ENLARGED_FEATURE_OFF} from '../consts';
import {InputHighlight} from './InputHighlight';
import {AirportCode, TimeOfObservation} from './components';

export const Form: FC<FormProps> = ({
                                        formSchema,
                                        reportType,
                                        closeFormHandler,
                                        highlightedFields = [],
                                        cor = {...ENLARGED_FEATURE_OFF},
                                        special = {...ENLARGED_FEATURE_OFF},
                                        speci = {...ENLARGED_FEATURE_OFF},
                                    }: FormProps) => {
    const styles = {
        formGrid: 'report-grid grid grid-cols-4 gap-x-5 gap-y-4',
        input: 'font-semibold outline-none bg-transparent',
        optionFlagContainer:
            'text-awos-navy-2 dark:text-awos-white-3 flex justify-end items-center',
        CorButton: 'px-5 py-1',
    };

    const {authHeader} = useKeycloak();
    const snackbar = useSnackbar();

    const [
        airportCodeId,
        reportTimeId,
        cavokButtonId,
        specialButtonId,
        modeId,
        speciButtonId,
    ] = ['icao', 'observation_time', 'cavok', 'special', 'mode', 'speci'];

    const formRegistering = useForm();

    const {
        reset,
        watch,
        setValue,
        setError,
        register,
        getValues,
        clearErrors,
        formState: {errors},
    } = formRegistering;

    setValue(modeId, 'semi-manual');

    const [specialState, setSpecialState] = useState(special.status);

    const [speciState, setSpeciState] = useState(speci.status);

    const [cavokState, setCavokState] = useState(false);

    const [preCAVOKValues, setPreCAVOKValues] = useState<CAVOKInputSave>({});

    const [corState, setCorState] = useState(cor.status);

    const [overrideState, setOverrideState] = useState(false);

    const formURL = `/reports/forms/${reportType.toLowerCase()}`;

    const params = new URLSearchParams();

    if (special.available && specialState) {
        params.append('special', String(specialState));
    }

    if (speci.available && speciState) {
        params.append('speci', String(speciState));
    }

    if (cor.available && corState) {
        params.append('cor', String(corState));
    }

    const {isFetching, isPaused} = useQuery({
        queryKey: ['reportData', params.toString()],
        queryFn: () => {
            return axios
                .get(`/api${formURL}?${params.toString()}`, {
                    headers: authHeader,
                })
                .then(({data}: { data: any }) => data); // eslint-disable-line
        },
        retry: true,
        initialData: null,
        onSuccess(data) {
            if (cavokState) {
                const tempPreCAVOKValues: CAVOKInputSave = {};
                Object.entries(data).forEach(([key, value]) => {
                    const matchedField = formSchema.formFields.find(
                        ({id}) => id === key
                    );
                    const cavokValue = get(matchedField, 'cavokValue');
                    if (!isUndefined(cavokValue)) {
                        tempPreCAVOKValues[key] = value as string;
                        data[key] = cavokValue;
                    }
                });

                if (Object.keys(tempPreCAVOKValues).length !== 0) {
                    setPreCAVOKValues(tempPreCAVOKValues);
                }
            }

            reset(data);
        },
    });

    const generateInputFields = (formFields: FormField[]): JSX.Element[] => {
        return formFields.map((formField) => {
            const reg = register(formField.id);

            return (
                <div
                    className={classNames(
                        `flex flex-col col-span-${formField.columnsWidth}`
                    )}
                    key={`div-${formField.id}`}
                >
                    <InputHighlight
                        reg={reg}
                        forceUpperCase
                        errors={errors}
                        inputField={formField}
                        watchSelf={watch(reg.name)}
                        cavok={cavokState}
                        clearErrorsSelf={() => clearErrors(reg.name)}
                        highighted={highlightedFields.includes(formField.id)}
                    />
                </div>
            );
        });
    };

    const handleCavokClick = () => {
        const newCAVOKState = !cavokState;
        setValue(cavokButtonId, newCAVOKState);
        setCavokState(newCAVOKState);
        const tempPreCAVOKValues: CAVOKInputSave = {};
        formSchema.formFields
            .filter((value) => value.cavokValue !== undefined)
            .forEach((inputField: FormField) => {
                if (newCAVOKState) {
                    tempPreCAVOKValues[inputField.id] = getValues(
                        inputField.id
                    );
                    setValue(inputField.id, inputField.cavokValue, {
                        shouldValidate: true,
                    });
                } else if (inputField.id in preCAVOKValues) {
                    setValue(inputField.id, preCAVOKValues[inputField.id], {
                        shouldValidate: true,
                    });
                }
            });
        if (Object.keys(tempPreCAVOKValues).length !== 0) {
            setPreCAVOKValues(tempPreCAVOKValues);
        }
    };

    const handleSpecialClick = () => {
        setSpecialState((p) => !p);
    };

    const renderCOROption = () => {
        return (
            <Button
                pressed={corState}
                onClick={() => {
                    setCorState((p) => !p);
                }}
                className={classNames('h-10 mr-2', styles.input)}
            >
                COR
            </Button>
        );
    };

    const renderCAVOKButton = () => {
        return (
            <>
                <input
                    type="checkbox"
                    className="hidden"
                    name={register(cavokButtonId).name}
                    ref={register(cavokButtonId).ref}
                />
                <Button
                    iconName="sun"
                    pressed={cavokState}
                    onClick={handleCavokClick}
                    classNameIcon="text-awos-yellow-1"
                    className={classNames('h-10', styles.input)}
                >
                    CAVOK
                </Button>
            </>
        );
    };

    const renderSPECIALButton = () => {
        return (
            <>
                <input
                    type="checkbox"
                    className="hidden"
                    name={register(specialButtonId).name}
                    ref={register(specialButtonId).ref}
                />
                <Button
                    pressed={specialState}
                    disabled={special.status}
                    onClick={handleSpecialClick}
                    className={classNames('h-10 mr-2', styles.input)}
                >
                    SPECIAL
                </Button>
            </>
        );
    };

    const handleSPECIClick = () => {
        setSpeciState((p) => !p);
    };
    const renderSPECIButton = () => {
        return (
            <>
                <input
                    type="checkbox"
                    className="hidden"
                    name={register(speciButtonId).name}
                    ref={register(speciButtonId).ref}
                />
                <Button
                    pressed={speciState}
                    disabled={speci.status}
                    onClick={handleSPECIClick}
                    className={classNames('h-10 mr-2', styles.input)}
                >
                    SPECI
                </Button>
            </>
        );
    };

    const renderOptionButtons = () => {
        return (
            <div className={styles.optionFlagContainer}>
                {cor.visible && renderCOROption()}
                {speci.visible && renderSPECIButton()}
                {special.visible && renderSPECIALButton()}
                {renderCAVOKButton()}
            </div>
        );
    };

    const getCurrentFormData = () => {
        const trimedValues = mapValues(getValues(), (value) => {
            return isString(value) ? trim(value) : value;
        });

        return {
            ...trimedValues,
            cavok: cavokState,
            special: specialState,
            cor: corState,
            speci: speciState,
        };
    };

    const showErrors = (
        error: ValidationRequestError,
        shouldNotify = false
    ) => {
        const response = get(error, ['response'], null);
        if (response) {
            const status = get(response, ['status'], 500);
            const failReasons = get(response, ['data', 'data', 'errors'], {});
            if (status === 400 && !isEmpty(failReasons)) {
                clearErrors();
                Object.keys(failReasons).forEach((key) => {
                    setError(key, {
                        type: 'validate',
                        message: JSON.stringify(failReasons[key]),
                    });
                });
            }

            if (shouldNotify && status !== 400) {
                showActionNotification({
                    messages: {
                        success: 'Report sent!',
                        fail: 'Error while sending report!',
                    },
                    isSuccessful: false,
                    snackbar,
                });
            }
        }
    };

    const validatePost = usePostData<unknown, ValidationRequestError>(
        `${formURL}/validate`,
        {
            onSuccess() {
                clearErrors();
            },
            onError(error) {
                showErrors(error, false);
            },
        }
    );

    const validateForm = async (e: FormEvent<HTMLFormElement>) => {
        preventDefault(e);
        validatePost.mutate(getCurrentFormData());
    };

    const submitPost = usePostData<unknown, ValidationRequestError>(
        `${formURL}?override=${overrideState}`,
        {
            onSuccess() {
                closeFormHandler();
            },
            onError(error) {
                showErrors(error, true);
            },
        }
    );

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        preventDefault(e);
        submitPost.mutate(getCurrentFormData());
    };

    return (
        <>
            {isFetching || isPaused ? <LoadingSpinner withShadow/> : null}
            <form
                className="flex-full-height"
                onSubmit={onSubmit}
                onBlur={validateForm}
            >
                <div className="flex justify-between">
                    <AirportCode codeId={watch(airportCodeId)}/>
                    <TimeOfObservation reportTime={watch(reportTimeId)}/>
                    {renderOptionButtons()}
                </div>
                <div className={styles.formGrid}>
                    {generateInputFields(formSchema.formFields)}
                </div>
                <div className="mt-auto ">
                    <div className="flex flex-row justify-end mt-9">
                        <Button
                            primary
                            type="submit"
                            iconName="warn"
                            className="mr-3"
                            disabled={isEmpty(errors)}
                            classNameIcon={classNames({
                                'text-awos-orange-1': !isEmpty(errors),
                            })}
                            onClick={() => setOverrideState(true)}
                        >
                            OVERRIDE
                        </Button>
                        <Button
                            primary
                            className="mr-3"
                            iconName="decline"
                            testId="discard-button"
                            onClick={closeFormHandler}
                        >
                            DISCARD
                        </Button>
                        <Button
                            primary
                            type="submit"
                            iconName="accept"
                            onClick={() => setOverrideState(false)}
                        >
                            APPROVE
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};
