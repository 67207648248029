import React from 'react';
import classNames from 'classnames';
import { Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

import Welcome from 'pages/Welcome';
import { isModalWindow } from 'helpers';
import Reports from 'pages/reports/Reports';
import { FeatureFlags, Roles } from 'consts';
import { ReportForm } from 'pages/reportForm';
import { Settings } from 'pages/settings/Settings';
import Dashboards from 'pages/dashboards/Dashboards';
import { StandardStationHome, SmallStationHome } from 'pages/home';
import { ReportNotificator } from 'tools/contexts/reportNotificationContext';

import {
    ProtectRouteByFlag,
    ProtectRouteByRole,
    ProtectFeatureByRole,
} from 'tools/protectors';

import './app-router.css';
import CamerasComponent from '../pages/camera/CamerasComponent';
import Tools from '../pages/tools/Tools';

type AppRouterProps = {
    darkMode: string;
};

const AppRouter: React.FunctionComponent<AppRouterProps> = ({
    darkMode,
}: AppRouterProps) => {
    return (
        <div
            className={classNames(
                'min-h-screen w-full flex flex-col router-container',
                {
                    'pt-status-bar pl-20': !isModalWindow(),
                }
            )}
        >
            <ReportNotificator />
            <Switch>
                <Route exact path="/">
                    <Welcome />
                </Route>
                <Route path="/home">
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <ProtectFeatureByRole
                            roles={{ excluded: [Roles.SMALL_STATION] }}
                        >
                            <StandardStationHome />
                        </ProtectFeatureByRole>
                        <ProtectFeatureByRole
                            roles={{ included: [Roles.SMALL_STATION] }}
                        >
                            <SmallStationHome />
                        </ProtectFeatureByRole>
                    </QueryParamProvider>
                </Route>
                <ProtectRouteByFlag
                    flag={FeatureFlags.DASHBOARDS}
                    path="/dashboards"
                >
                    <Dashboards darkMode={darkMode} />
                </ProtectRouteByFlag>
                <ProtectRouteByFlag flag={FeatureFlags.REPORTS} path="/reports">
                    <QueryParamProvider ReactRouterRoute={Route}>
                        <Reports />
                    </QueryParamProvider>
                </ProtectRouteByFlag>
                <ProtectRouteByFlag flag={FeatureFlags.TOOLS} path="/tools">
                    <Tools />
                </ProtectRouteByFlag>
                <ProtectRouteByRole
                    path="/report-modal"
                    roles={{ included: [Roles.CREATE_REPORTS] }}
                >
                    <ReportForm />
                </ProtectRouteByRole>
                <ProtectRouteByRole
                    path="/settings"
                    roles={{ included: [Roles.ACCESS_SETTINGS] }}
                >
                    <Settings />
                </ProtectRouteByRole>
                <ProtectRouteByFlag flag={FeatureFlags.CAMERAS} path="/live">
                    <CamerasComponent />
                </ProtectRouteByFlag>
            </Switch>
        </div>
    );
};

export default AppRouter;
