import React, { FC } from 'react';

import { ReportType } from 'consts';

import { Form } from '../forms';
import { ENLARGED_FEATURE_OFF } from '../consts';
import { metarSchema } from '../forms/ReportSchemas';
import { ReportNotificationMessage } from '../forms/components';
import { EnlargedFeature, ReportModalConfiguration } from '../types';

type MetarReportProps = {
    cor?: EnlargedFeature;
    speci?: EnlargedFeature;
    onReportDiscard: () => void;
    config: ReportModalConfiguration;
};

const DEFAULT_COR = { ...ENLARGED_FEATURE_OFF };
const DEFAULT_SPECI = { ...ENLARGED_FEATURE_OFF };

export const MetarReport: FC<MetarReportProps> = ({
    config,
    onReportDiscard,
    cor = DEFAULT_COR,
    speci = DEFAULT_SPECI,
}) => (
    <>
        <ReportNotificationMessage message={config.data} />
        <Form
            cor={cor}
            speci={speci}
            key="metar"
            formSchema={metarSchema}
            reportType={ReportType.METAR}
            closeFormHandler={onReportDiscard}
        />
    </>
);
