export enum FeatureFlags {
    RVR_TABLE = 'rvr_table',
    CLOUDS_TABLE = 'clouds_table',
    WEATHER_TABLE = 'weather_table',
    PRESSURE_TABLE = 'pressure_table',
    LEFT_RUNWAY = 'left_runway',
    RIGHT_RUNWAY = 'right_runway',
    GAS_TABLE = 'gas_table',
    REPORTS = 'reports',
    REPORTS_CREATE = 'reports_create', // this feature flag on FE only, BE does not response it. Used as a checker on HomePage
    REPORTS_CHECK = 'reports_check', // this feature flag on FE only, BE does not response it. Used as a checker on HomePage
    DASHBOARDS = 'dashboards',
    TOOLS = 'tools',
    TAF = 'taf',
    CAMERAS = 'cameras',
}

export enum Roles {
    GRAFANA_ADMIN = 'grafana-admin',
    GRAFANA_EDITOR = 'grafana-editor',
    CREATE_REPORTS = 'create-reports',
    REALM_DEFAULT = 'default-roles-awos-dev-realm',
    SET_ACTIVE_RUNWAY = 'set-active-runway',
    ACCESS_SETTINGS = 'access-settings',
    SMALL_STATION = 'small-station',
}

export enum ReportType {
    MET = 'MET',
    TAF = 'TAF',
    METAR = 'METAR',
}

export enum NotificationReason {
    REPORT_SPECIAL = 'special',
    REPORT_SPECI = 'speci',
    REPORT_SCHEDULED = 'schedule',
    REPORT_CREATED = 'new_report',
}

export const REPORT_CHANNEL = 'report-channel';
// REPORT_STORAGE_KEY is used in Keycloak login form template
export const REPORT_STORAGE_KEY = 'rsk';
export const REPORT_STORAGE_TEMPLATE_KEY = 'rstk';
export enum ReportStorageReason {
    WINDOW_LOADING = 'WINDOW_LOADING',
    WINDOW_PREOPEN = 'WINDOW_PREOPEN',
    WINDOW_HEARTBEAT = 'WINDOW_HEARTBEAT',
}

export const NEW_REPORT_ID = 'new-report-form';
export const REPORT_WINDOW_NAME = 'report-modal-window';
export enum ModalEvents {
    CLOSE = 'report.close',
    ALIVE = 'report.alive',
    SHARE = 'report.share',
    MOUNTED = 'report.mounted',
    LIDER_SELECTED = 'report.liderSelected',
}
