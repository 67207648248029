import { UseQueryResult } from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';

import { CardI } from 'pages/generic/card';
import { useGetData } from 'tools/hooks/getData';
import { WindroseData } from 'pages/generic/Windrose';
import { ReportListData } from 'pages/home/components/reportFooter';
import { TelegramTableElementsList } from 'pages/home/components/telegramTable';

type HomeContextProps = {
    children: JSX.Element;
};

type HomeData<T> = {
    data: T;
    isSyncFailed: boolean;
};

type HomeDataContextType = {
    weatherCards: HomeData<CardI[]> | undefined;
    windrose: HomeData<WindroseData> | undefined;
    reportList: HomeData<ReportListData> | undefined;
    tafElementsList: HomeData<TelegramTableElementsList> | undefined;
    validLastMetReport: HomeData<TelegramTableElementsList> | undefined;
};

const initialContextValue = {
    windrose: undefined,
    reportList: undefined,
    weatherCards: undefined,
    tafElementsList: undefined,
    validLastMetReport: undefined,
};

function pick<T>({
    data,
    failureCount,
    isPaused,
}: UseQueryResult<T>): HomeData<T> {
    return { data: data as T, isSyncFailed: isPaused || !!failureCount };
}
const HomeDataContext = createContext<HomeDataContextType>(initialContextValue);

const baseOptions = {
    enabled: true,
    intervalMs: 2000,
};

export const HomeDataContextProvider: React.FunctionComponent<
    HomeContextProps
> = ({ children }: HomeContextProps) => {
    const weatherCards = useGetData<CardI[]>({
        ...baseOptions,
        endpoint: '/tiles',
        queryName: 'weatherCards',
        initialValue: [],
    });

    const windrose = useGetData<WindroseData>({
        ...baseOptions,
        endpoint: '/wind?rwy=0',
        queryName: 'windroseLeft',
        initialValue: { runway_active: false },
    });

    const tafElementsList = useGetData<TelegramTableElementsList>({
        ...baseOptions,
        endpoint: '/reports/taf',
        queryName: 'tafElementsList',
        initialValue: [],
    });

    const reportList = useGetData<ReportListData>({
        ...baseOptions,
        endpoint: '/reports?desc_sorting=1&per_page=10&report_type=METAR',
        queryName: 'reportList',
        initialValue: { reports: [] },
    });

    const validLastMetReport = useGetData<TelegramTableElementsList>({
        ...baseOptions,
        endpoint: '/reports/local',
        queryName: 'validLastMetReport',
        initialValue: [],
    });

    return (
        <HomeDataContext.Provider
            value={{
                windrose: pick<WindroseData>(windrose),
                weatherCards: pick<CardI[]>(weatherCards),
                reportList: pick<ReportListData>(reportList),
                tafElementsList:
                    pick<TelegramTableElementsList>(tafElementsList),
                validLastMetReport:
                    pick<TelegramTableElementsList>(validLastMetReport),
            }}
        >
            {children}
        </HomeDataContext.Provider>
    );
};

export const useHomeDataContext = (): HomeDataContextType =>
    useContext<HomeDataContextType>(HomeDataContext);
