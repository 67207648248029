import { compact } from 'lodash';
import { FC, useCallback } from 'react';

export const EMPTY_NODE_TEXT = 'No Report available';
export const TELEGRAM_TABLE_CLASSNAME = 'telegram-table';
export const EMPTY_TABLE_CLASSNAME = `${TELEGRAM_TABLE_CLASSNAME}-empty`;

const emptyStyles = `${EMPTY_TABLE_CLASSNAME} p-5 h-32 border`;

const tableStyles = [
    'w-full table-fixed text-center',
    TELEGRAM_TABLE_CLASSNAME,
    'text-awos-navy-2 dark:text-c-table-dark-text',
].join(' ');

const thStyles = [
    'pb-0',
    'text-telegram_table_th overflow-hidden font-roboto',
    'bg-awos-white-2 dark:bg-c-table-dark-horizontalHeaderCell',
    'border border-t-0 dark:border-t border-c-table-light-border dark:border-c-table-dark-border',
].join(' ');

const tdStyles = [
    'py-2 md:px-2',
    'font-bold text-sm font-roboto',
    'border border-t-0 border-c-table-light-border dark:border-c-table-dark-border',
    'bg-white hover:bg-c-table-light-rowCellHover dark:bg-c-table-dark-cell dark:hover:bg-c-table-dark-rowCellHover',
].join(' ');

export type TelegramTableElement = {
    name: string;
    value: string;
    size?: number;
};

export type TelegramTableElementsList = Array<TelegramTableElement[]>;

type TelegramTableProps = {
    data?: TelegramTableElementsList;
};

export const TelegramTable: FC<TelegramTableProps> = ({ data }) => {
    const getKey = useCallback((str, idx) => encodeURI(`${str},${idx}`), []);

    if (!data || !data.length) {
        return <div className={emptyStyles}>No Report available</div>;
    }

    return (
        <>
            {compact(data).map((table, tableIndex) => {
                const { ths, tds, colSpans } = table.reduce<{
                    ths: string[];
                    tds: string[];
                    colSpans: number[];
                }>(
                    (current, element) => {
                        const { name, value, size = 1 } = element;
                        current.ths.push(name);
                        current.tds.push(value);
                        current.colSpans.push(size);
                        return current;
                    },
                    {
                        ths: [],
                        tds: [],
                        colSpans: [],
                    }
                );

                return (
                    <table
                        key={getKey(ths.join(), tableIndex)}
                        className={tableStyles}
                    >
                        <thead>
                            <tr>
                                {ths.map((th: string, headIndex) => (
                                    <th
                                        key={getKey(th, headIndex)}
                                        className={thStyles}
                                        colSpan={colSpans[headIndex]}
                                    >
                                        {th}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {tds.map((td: string, bodyIndex) => (
                                    <td
                                        key={getKey(td, bodyIndex)}
                                        className={tdStyles}
                                        colSpan={colSpans[bodyIndex]}
                                    >
                                        {td}
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                );
            })}
        </>
    );
};
