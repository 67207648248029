import React from 'react';
import { isNil } from 'lodash';
import HLSPlayerComponent from './HLSPlayerComponent';
import { useGetData } from '../../tools/hooks/getData';

type Cameras = Camera[];

type Camera = {
    name: string;
    endpoint: string;
};

const CamerasComponent: React.FunctionComponent = () => {
    const { data: cameras } = useGetData<
        Cameras | undefined
    >({
        retry: true,
        enabled: true,
        initialValue: [],
        endpoint: '/cameras',
        queryName: 'cameras',
    });

    if (isNil(cameras)) {
        return <div>Loading...</div>;
    }

    if (cameras.length === 0) {
        return <div>No cameras available</div>;
    }

    if (cameras.length === 1) {
        return (
            <div>
                <HLSPlayerComponent
                    name={cameras[0].name}
                    endpoint={cameras[0].endpoint}
                />
            </div>
        );
    }

    if (cameras.length > 1) {
        return (
            <div className="grid grid-cols-2">
                {cameras.map((camera) => (
                    <HLSPlayerComponent
                        key={camera.name}
                        name={camera.name}
                        endpoint={camera.endpoint}
                    />
                ))}
            </div>
        );
    }

    return <div>Something went wrong</div>;
};

export default CamerasComponent;
