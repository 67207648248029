import React, {FC} from 'react';

import {ReportType} from 'consts';

import {Form} from '../forms';
import {ENLARGED_FEATURE_OFF, ENLARGED_FEATURE_ON} from '../consts';
import {metarSchema} from '../forms/ReportSchemas';
import {ReportNotificationMessage} from '../forms/components';
import {EnlargedFeature, ReportModalConfiguration} from '../types';

type SpeciReportProps = {
    cor?: EnlargedFeature;
    onReportDiscard: () => void;
    config: ReportModalConfiguration;
};

const DEFAULT_COR = {...ENLARGED_FEATURE_OFF};

export const SpeciReport: FC<SpeciReportProps> = ({
                                                      config,
                                                      onReportDiscard,
                                                      cor = DEFAULT_COR,
                                                  }) => (
    <>
        <ReportNotificationMessage message={config.data}/>
        <Form
            cor={cor}
            speci={{...ENLARGED_FEATURE_ON, visible: false}}
            key="metar"
            formSchema={metarSchema}
            reportType={ReportType.METAR}
            closeFormHandler={onReportDiscard}
            highlightedFields={config.fields}
        />
    </>
);
