import {BroadcastChannel} from 'broadcast-channel';
import React, {FC, useEffect, useState} from 'react';

import {
    ReportType,
    ModalEvents,
    NEW_REPORT_ID,
    REPORT_CHANNEL,
    REPORT_STORAGE_KEY,
    ReportStorageReason,
    REPORT_STORAGE_TEMPLATE_KEY,
} from 'consts';
import AwosIcon from 'pages/generic/AwosIcon';
import BasicWindowContainer from 'pages/generic/BasicWindowContainer';
import {getWithExpiry, setWithExpiry, safelyParseJSON} from 'helpers';

import {
    MetReport,
    MetarReport,
    SpecialReport,
    CombineReport,
} from './components/reports';
import {ReportModalConfiguration} from './components/types';
import {SpeciReport} from "./components/reports/SpeciReport";

const styles = {
    reportIcon: 'inline-block h-5 ml-1.5 mr-4',
    windowContainerContainer:
        'overflow-auto min-h-screen w-full flex-full-height',
    windowContainerTopLeft:
        'text-awos-navy-2 dark:text-c-basicWindowContainer-dark-sideText',
    windowContainerTopBar: `bg-awos-white-2 text-white dark:bg-c-basicWindowContainer-dark-topBar`,
};

export const ReportForm: FC = () => {
    const [close, setClose] = useState(false);
    const [config] = useState<ReportModalConfiguration | null>(
        safelyParseJSON(getWithExpiry(REPORT_STORAGE_TEMPLATE_KEY))
    );

    const discardHandler = () => {
        setClose(true);
        window.localStorage.removeItem(REPORT_STORAGE_KEY);
        window.localStorage.removeItem(REPORT_STORAGE_TEMPLATE_KEY);
        window.close();
    };

    useEffect(() => {
        const channelInstance = new BroadcastChannel(REPORT_CHANNEL);
        if (!config) {
            return discardHandler();
        }

        const id = setInterval(() => {
            if (!close) {
                setWithExpiry(
                    REPORT_STORAGE_KEY,
                    ReportStorageReason.WINDOW_HEARTBEAT,
                    2
                );
                channelInstance.postMessage({type: ModalEvents.ALIVE});
            } else {
                clearInterval(id);
            }
        }, 500);

        return () => clearInterval(id);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getReport = (modalConfig: ReportModalConfiguration) => {
        if (modalConfig.special)
            return (
                <SpecialReport
                    config={modalConfig}
                    onReportDiscard={discardHandler}
                />
            );

        if (modalConfig.speci)
            return <SpeciReport
                config={modalConfig}
                onReportDiscard={discardHandler}/>

        if (modalConfig.reportId === NEW_REPORT_ID) {
            return (
                <CombineReport
                    config={modalConfig}
                    onReportDiscard={discardHandler}
                />
            );
        }

        if (modalConfig.reportType === ReportType.MET) {
            return (
                <MetReport
                    config={modalConfig}
                    onReportDiscard={discardHandler}
                />
            );
        }

        if (modalConfig.reportType === ReportType.METAR) {
            return (
                <MetarReport
                    config={modalConfig}
                    onReportDiscard={discardHandler}
                />
            );
        }

        return null;
    };

    return (
        config && (
            <div className="text-awos-navy-2 dark:text-awos-white-3">
                <BasicWindowContainer
                    containerWithInputs
                    styles={{
                        topBar: styles.windowContainerTopBar,
                        topLeft: styles.windowContainerTopLeft,
                        container: styles.windowContainerContainer,
                    }}
                    topLeft={
                        <span className="inline font-semibold text-lg">
                            <AwosIcon
                                iconName="report"
                                styles={styles.reportIcon}
                            />
                            <span className="align-middle">
                                GENERATE {config?.reportPopupTitle} REPORT
                            </span>
                        </span>
                    }
                >
                    <div className="mx-6 my-5 flex-full-height">
                        {getReport(config)}
                    </div>
                </BasicWindowContainer>
            </div>
        )
    );
};
